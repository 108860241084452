import React, { createContext, useRef } from "react";
import { CommonActions, DrawerActions } from "@react-navigation/routers";
import { CheckPoint } from "./checkpoints";

const DrawerRouteContext = createContext({
  updateRoute: (name: string, params: Record<string, any>) =>
    console.debug("updateRoute Not Implemented"),
  goBack: (navigation) => console.debug("goBack Not Implemented"),
  navigateTo: (name: string, navigation, params?: any) =>
    console.debug("navigateTo Not Implemented"),
  setCheckpoint: (navigation, flag) =>
    console.debug("setCheckpoint Not Implemented"),
  navigateToCheckpoint: (navigation, flag) =>
    console.debug("navigateToCheckpoint Not Implemented"),
  hasCheckpointFlag: (flag) => Boolean(false),
});

const DrawerRouteContextProvider = (props) => {
  const currentRoute = useRef({ name: "", params: undefined });
  const checkpoint = useRef({
    conversation: {
      navigationState: null,
    },
    default: {
      navigationState: {
        index: 0,
        routes: [{ name: "Drawers" }],
      },
    },
  });

  const setCheckpoint = (navigation, flag) => {
    console.log("Setting Checkpoint", flag);
    let navObj = navigation;
    let key = CheckPoint.Default;

    if (flag) {
      key = flag;
    }

    while (!navObj.getState().routeNames.includes("Home")) {
      navObj = navObj.getParent();
    }

    checkpoint.current[key].navigationState = navObj.getState();
  };

  const navigateToCheckpoint = (navigation, flag) => {
    let key = CheckPoint.Default;

    if (flag) {
      key = flag;
    }

    navigation.dispatch(
      CommonActions.reset(checkpoint.current[key].navigationState)
    );

    if (flag) {
      checkpoint.current[flag].navigationState = null;
    }
  };

  const hasCheckpointFlag = (flag) => {
    return checkpoint.current[flag].navigationState !== null;
  };

  const updateRoute = (name: string, params: Record<string, any>) => {
    currentRoute.current.name = name;
    currentRoute.current.params = params;
  };

  /**
   * Can be used by screens that are in a nested navigator that are part of
   * parent Drawer Navigator.
   *
   * UserProfile | Conversation | Bulletin
   */
  const goBack = (navigation) => {
    const routeName = currentRoute.current.name ?? "Home";

    // Will return drawer's routes
    const routes = navigation.getParent().getState().routeNames;
    const parentScreenIndex = routes.indexOf(routeName);

    if (parentScreenIndex === -1) {
      // Current Screen is not part of the drawer navigation. This most likely
      // will be called from nested screen of a Drawer nested stack
      navigation.goBack();
    } else {
      navigation.dispatch(
        DrawerActions.jumpTo(routeName, currentRoute.current.params)
      );
    }
  };

  /**
   * Can be used by screens that are at the same level of the screens that are
   * part of the drawer navigator.
   *
   * IntegrationListScreen | NewDmScreen | MessageDetailScreen | MessageDetail |
   * FolderDetail
   */
  const navigateTo = (name: string, navigation, params = {}) => {
    navigation.dispatch(DrawerActions.jumpTo(name, params));
  };

  const value = {
    updateRoute,
    goBack,
    navigateTo,
    setCheckpoint,
    navigateToCheckpoint,
    hasCheckpointFlag,
  };

  return (
    <DrawerRouteContext.Provider value={value}>
      {props.children}
    </DrawerRouteContext.Provider>
  );
};

export default DrawerRouteContextProvider;
export { DrawerRouteContext };
