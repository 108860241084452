import React, { useMemo, useEffect } from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { LinkingOptions, NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import Toast from "react-native-toast-message";

import { Banner, DrawerContent, ModalHeader } from "~riata/components";
import { navigatorRef } from "~riata/navigation";

import { DashboardScreen } from "../dashboard/screens";
import { NavigationIcon } from "~riata/components/icons";
import DebugScreen from "../debug/DebugScreen";
import IntegrationListScreen from "../integrations/IntegrationListScreen";
import {
  ChatChannelsScreen,
  ConversationStack,
  MessageDetailScreen,
  NewChannelStack,
} from "../chat";

import UserProfileScreen from "../profile/UserProfileScreen";
import ChangePasswordScreen from "../profile/ChangePasswordScreen";
import { FolderDetailScreen, FolderListScreen } from "../folders";
import { IntegrationDetail } from "../integrations/IntegrationDetail";
import {
  useSubscriptions,
  useBulletinTakeOver,
  useRegisterNotifications,
  useToastMessageConfig,
  useColors,
  useOffDuty,
} from "~riata/hooks";
import { Platform, Linking, useWindowDimensions } from "react-native";
import { WIDTH_FOR_PERMANENT_DRAWER } from "~riata/theme/common";
import {
  BulletinCreationStack,
  BulletinScreen,
  BulletinStack,
} from "../bulletins";
import {
  AttachmentImageGallery,
  IntegrationAttachmentDetail,
} from "../attachments";
import DrawerRouteContextProvider from "~riata/navigation/DrawerRouteContext";
import { BookmarkDetailsScreen } from "../bookmarks";
import { OffDutyModal } from "../announcements";
import { useGetCurrentUser } from "~riata/graphql";
import { UserProfileScreen as UserScreen } from "../users/UserProfileScreen";

const RootStack = createStackNavigator<RootStackParamList>();
const Drawer = createDrawerNavigator();

const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  // TODO Support Universal Links
  // Note: this comes from babel
  prefixes: [`${process.env.CALLBACK_SCHEME}://`],
  config: {
    screens: {
      // We need this outer "Drawers" level so that our configuration matches the structure of the
      // elements. For the URLs, it does not matter since we are starting with Home at "" (i.e., string
      // is empty, so root) and the rest of the routes will build onto that empty string.
      Drawers: {
        // If you leave `path` and `initialRouteName` blank, React seems to just pick the screen with a
        // path of "". While this is convenient, many examples have both set. For the sake of clarity,
        // both are set here as well.
        path: "",
        initialRouteName: "Home",
        screens: {
          Home: "",
          OffDutyModal: "announcements/offDutyModal",
          Messages: "messages",
          Bulletins: "bulletins/:filter?",
          Folders: "folders",
          Conversation: {
            screens: {
              ConversationDetail: "channel/:channel",
              ConversationInfo: {
                screens: {
                  ConversationInfoSheet: "channel/:channel/info",
                  ChannelUserProfileScreen:
                    "channel/:channel/info/user/:userId",
                  AddUsersModal: "channel/:channel/info/addUsers",
                },
              },
              MessageActionSheet: "channel/:channel/:message/action",
              ReactionDetailScreen:
                "channel/:channel/:message/reaction/:reaction",
              AttachmentActionSheet: "channel/:channel/new/attachment",
              AttachmentImageGallery: "channel/:channel/:message/attachments",
              IntegrationAttachmentActionSheet:
                "channel/:channel/new/attachment/integration/:integration/",
            },
          },
          IntegrationDetail: "integration/:id/:path?",
          BookmarkDetail: "bookmark/:id",
          Bulletin: {
            screens: {
              BulletinDetails: "bulletin/:id",
              BulletinComments: "bulletin/:id/comments",
              BulletinRecipients: "bulletin/:id/recipients",
              IntegrationAttachmentDetail:
                "bulletin/:id/attachment/:attachment",
              BulletinAttachments: "bulletin/:id/attachments",
            },
          },
          BulletinCreation: {
            screens: {
              BulletinInboxSelect: "bulletin/new/inboxSelect",
              BulletinComposeCore: {
                screens: {
                  BulletinCompose: "bulletin/new",
                  BulletinDraft: "bulletin/draft/:id",
                  IntegrationAttachmentActionSheet:
                    "bulletin/new/attachment/integration/:integration",
                  DateSelectActionSheet:
                    "bulletin/new/dateSelect/:dateKey?/:isDraft?",
                },
              },
            },
          },
          FolderDetail: "folder/:tag",
          MessageDetail: "message/:id",
          NewDmScreen: "messages/new",
          UserProfile: "me",
          ProfileScreen: "profile/:user",
        },
      },
    },
  },
};

const UserProfileStack = () => {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="Profile"
    >
      <Stack.Screen name="Profile" component={UserProfileScreen} />
      <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} />
    </Stack.Navigator>
  );
};

const DrawerComponent = ({ navigation }) => {
  const { width } = useWindowDimensions();
  const { newColors } = useColors();
  const { user } = useGetCurrentUser();

  const drawerType = useMemo(
    () => (width > WIDTH_FOR_PERMANENT_DRAWER ? "permanent" : "front"),
    [width]
  );

  useRegisterNotifications();
  useSubscriptions({ navigation });
  useBulletinTakeOver({ navigation });

  useEffect(() => {
    if (user?.offDuty) {
      navigation.navigate("OffDutyModal");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  return (
    <DrawerRouteContextProvider>
      <Drawer.Navigator
        drawerContent={(props) => <DrawerContent {...props} />}
        screenOptions={{
          drawerActiveTintColor: newColors.text.regular.main,
          drawerInactiveTintColor: newColors.text.regular.main,
          drawerInactiveBackgroundColor: "transparent",
          drawerActiveBackgroundColor: newColors.drawer.selected.main,
          drawerItemStyle: {
            marginTop: 16,
            marginHorizontal: 16,
            borderRadius: 8,
          },
          drawerType,
          headerShown: false,
        }}
      >
        <Drawer.Group>
          <Drawer.Screen
            name="Home"
            component={DashboardScreen}
            options={{
              drawerIcon: () => <NavigationIcon name="home" />,
            }}
          />
          <Drawer.Screen
            name="Messages"
            component={ChatChannelsScreen}
            options={{
              drawerIcon: () => <NavigationIcon name="chat_outlined" />,
            }}
          />
          <Drawer.Screen
            name="Bulletins"
            component={BulletinScreen}
            options={{
              drawerIcon: () => <NavigationIcon name="bulletins" />,
            }}
          />

          <Drawer.Screen
            name="Folders"
            component={FolderListScreen}
            options={{
              drawerIcon: () => <NavigationIcon name="folder" />,
            }}
          />
          <Drawer.Screen
            name="Integrations"
            component={IntegrationListScreen}
            options={{
              drawerIcon: () => <NavigationIcon name="integrations" />,
            }}
          />
          <Drawer.Screen
            name="Debug"
            component={DebugScreen}
            options={{
              drawerIcon: () => <NavigationIcon name="debug" />,
              drawerItemStyle: {
                display: process.env.ENVIRONMENT !== "prod" ? "flex" : "none",
                marginTop: 16,
                marginHorizontal: 16,
                borderRadius: 8,
              },
            }}
          />
        </Drawer.Group>

        <Drawer.Group
          screenOptions={{
            drawerItemStyle: { display: "none" },
          }}
        >
          <Drawer.Screen
            name="IntegrationDetail"
            component={IntegrationDetail}
          />
          <Drawer.Screen
            name="BookmarkDetail"
            component={BookmarkDetailsScreen}
          />
          <Drawer.Screen name="UserProfile" component={UserProfileStack} />
          <Drawer.Screen name="Conversation" component={ConversationStack} />
          <Drawer.Screen name="NewDmScreen" component={NewChannelStack} />
          <Drawer.Screen
            name="MessageDetailScreen"
            component={MessageDetailScreen}
          />
          <Drawer.Screen name="MessageDetail" component={MessageDetailScreen} />
          <Drawer.Screen name="Bulletin" component={BulletinStack} />
          <Drawer.Screen
            name="BulletinCreation"
            component={BulletinCreationStack}
          />
          <Drawer.Screen name="ProfileScreen" component={UserScreen} />
          <RootStack.Screen
            name="FolderDetail"
            component={FolderDetailScreen}
          />
        </Drawer.Group>
      </Drawer.Navigator>
    </DrawerRouteContextProvider>
  );
};

const generateModalScreens = (Wrapper) => {
  return (
    <Wrapper.Group
      screenOptions={{
        headerShown: true,
        headerLeft: false,
        presentation: "modal",
      }}
    >
      <Wrapper.Screen
        name="IntegrationAttachmentDetail"
        options={{
          header: ModalHeader,
        }}
        component={IntegrationAttachmentDetail}
      />
    </Wrapper.Group>
  );
};

const generateAnnouncementsModalScreens = (Wrapper) => {
  return (
    <Wrapper.Group
      screenOptions={{
        cardOverlayEnabled: true,
        cardStyle: { backgroundColor: "transparent", opacity: 1 },
        cardStyleInterpolator: ({ current: { progress } }) => ({
          cardStyle: {
            opacity: progress.interpolate({
              inputRange: [0, 0.5, 0.9, 1],
              outputRange: [0, 0.25, 0.7, 1],
            }),
          },
        }),
        headerShown: false,
        presentation: "transparentModal",
      }}
    >
      <Wrapper.Screen name="OffDutyModal" component={OffDutyModal} />
    </Wrapper.Group>
  );
};

export const Riata = () => {
  const { config } = useToastMessageConfig();
  const { isVisible, hideBanner, onPress, variant, generateBannerMessage } =
    useOffDuty();

  // Legacy: if someone bookmarked a React Web link with `Drawers` in the URL,
  // that will no longer work. This workaround removes the `Drawers` segment
  // so the URL works.
  useEffect(() => {
    if (Platform.OS === "web") {
      Linking.getInitialURL().then((url) => {
        if (url.match(/Drawers/)) {
          window.location.replace(url.replace(/Drawers\/?/, ""));
        }
      });
    }
  }, []);

  return (
    <>
      <NavigationContainer
        // TODO Add fallback component for general loading
        linking={linking}
        ref={navigatorRef as any}
      >
        <RootStack.Navigator screenOptions={{ headerShown: false }}>
          <RootStack.Group>
            <RootStack.Screen name="Drawers" component={DrawerComponent} />
            <RootStack.Screen
              name="AttachmentImageGallery"
              component={AttachmentImageGallery}
            />
          </RootStack.Group>
          {generateAnnouncementsModalScreens(RootStack)}
          {generateModalScreens(RootStack)}
        </RootStack.Navigator>
      </NavigationContainer>
      <Banner
        isVisible={isVisible}
        onDismiss={hideBanner}
        variant={variant}
        onPress={onPress}
        generateBannerMessage={generateBannerMessage}
      />
      <Toast config={config} />
    </>
  );
};
