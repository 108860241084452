import { useIsFocused } from "@react-navigation/core";
import { useEffect } from "react";

const useUpdateSelectedRouteIfFocused = (
  updateRouteCallback,
  condition = true
) => {
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused && condition) {
      updateRouteCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);
};

export { useUpdateSelectedRouteIfFocused };
