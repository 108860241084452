import { useNavigation, useRoute } from "@react-navigation/core";
import { useContext } from "react";
import { DrawerRouteContext } from "~riata/navigation/DrawerRouteContext";

const useDrawerNavigation = () => {
  const {
    updateRoute,
    goBack,
    navigateTo,
    setCheckpoint,
    navigateToCheckpoint,
    hasCheckpointFlag,
  } = useContext(DrawerRouteContext);

  const navigation = useNavigation<any>();
  const route = useRoute();

  return {
    updateSelectedRoute: () => updateRoute(route.name, route.params ?? {}),
    goBack: () => goBack(navigation),
    navigateTo: (name, params = {}) => navigateTo(name, navigation, params),
    setCheckpoint: (flag = null) => setCheckpoint(navigation, flag),
    navigateToCheckpoint: (flag = null) =>
      navigateToCheckpoint(navigation, flag),
    hasCheckpointFlag: (flag) => hasCheckpointFlag(flag),
  };
};

export { useDrawerNavigation };
