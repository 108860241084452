import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

// Components
import { Row } from "~riata/components/layout";
import { Text } from "~riata/components/typography";
import { OffDutyLabel } from "../../OffDutyLabel";
import { UserAvatar } from "~riata/components/avatars";

// Hooks
import { useColors, useComposeStyles } from "~riata/hooks";

// Types
import { UserInfoHeaderComponent } from "./types";

const UserInfoHeader: UserInfoHeaderComponent = ({
  containerStyles = {},
  avatarContainerStyles = {},
  user,
  title = "",
  subtitle = "",
  statusEnabled = false,
  offDuty = false,
  onBackPress,
}) => {
  const { newColors } = useColors();

  const composedContainerStyles = useComposeStyles(
    styles.container,
    containerStyles
  );

  const Details = useMemo(() => {
    const details = [];

    if (title) {
      details.push(
        <Row justify="center">
          <Text variant="title2">{title}</Text>
        </Row>
      );
    }

    if (subtitle) {
      details.push(
        <Row justify="center" style={styles.topMargin}>
          <Text variant="subhead" color={newColors.text.caption.main}>
            {subtitle}
          </Text>
        </Row>
      );
    }

    if (statusEnabled) {
      details.push(
        <Row justify="center" style={styles.topMargin}>
          <OffDutyLabel offDuty={offDuty} />
        </Row>
      );
    }

    return details;
  }, [newColors, offDuty, statusEnabled, subtitle, title]);

  return (
    <View style={composedContainerStyles}>
      <Row style={composedContainerStyles} justify="center">
        <UserAvatar
          disabled
          user={user}
          size={72}
          showStatus={false}
          style={{ container: avatarContainerStyles }}
        />
      </Row>
      <View style={styles.titleWrapper}>{Details}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  titleWrapper: {
    marginTop: 20,
  },
  topMargin: {
    marginTop: 12,
  },
});

export default UserInfoHeader;
