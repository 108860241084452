import React, { useEffect, useMemo } from "react";
import { StyleSheet, View, ScrollView, SafeAreaView } from "react-native";
import {
  Text,
  Separator,
  ChannelInfoActions,
  AppHeader,
  HeaderActionButton,
  UserInfoHeader,
  AttributeBadge,
  LoadingErrorGuard,
} from "~riata/components";
import { useColors, useComposeStyles } from "~riata/hooks";
import { useGetUser } from "~riata/graphql/queries/GetUser";
import { useGetOrCreateDm } from "~riata/graphql/mutations/CreateDm";
import { CommonActions } from "@react-navigation/routers";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";
import { CheckPoint } from "~riata/navigation/checkpoints";
import { useGetCurrentUser } from "~riata/graphql";

const UserProfileScreen = ({ navigation, route }) => {
  const userId = route.params?.user ?? "";

  const { user: currentUser } = useGetCurrentUser();
  const { user, loading, error, refetch } = useGetUser({
    filter: !userId.includes("@")
      ? { id: userId }
      : { query: userId.replace("@", "") },
  });

  const { navigateToCheckpoint, hasCheckpointFlag } = useDrawerNavigation();

  const { newColors } = useColors();

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.background.main,
  });

  const { getOrCreateDirectMessageChannel, channel } = useGetOrCreateDm();

  const onBackHandler = () => {
    if (hasCheckpointFlag(CheckPoint.Conversation)) {
      navigateToCheckpoint(CheckPoint.Conversation);
    } else {
      navigateToCheckpoint();
    }
  };

  const onRefetch = () => {
    if (refetch) {
      refetch();
    }
  };

  const actions = useMemo(() => {
    const actions = [];

    if (!user || user?.username === currentUser?.username) {
      return [];
    }

    actions.push({
      label: "Chat",
      iconName: "chat_outlined",
      onPressHandler: () => {
        if (user?.id) {
          getOrCreateDirectMessageChannel({
            variables: {
              recipients: [user.id],
            },
          });
        }
      },
    });

    return actions;
  }, [user, currentUser, getOrCreateDirectMessageChannel]);

  const attributesList = useMemo(() => {
    const attributes =
      user?.attributes.edges.map((edge) => edge.node.name) || [];

    return attributes.map((attribute, idx) => (
      <AttributeBadge key={idx} title={attribute} />
    ));
  }, [user]);

  useEffect(() => {
    if (channel) {
      navigation.dispatch(
        CommonActions.reset({
          index: 2,
          routes: [
            { name: "Home" },
            { name: "Messages" },
            {
              name: "Conversation",
              params: {
                screen: "ConversationDetail",
                params: { channel },
              },
            },
          ],
        })
      );
    }
    // prevent {navigation} from triggering a re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <SafeAreaView style={containerStyles}>
      <AppHeader
        backgroundColor={newColors.background.main}
        left={<HeaderActionButton variant="back" onPress={onBackHandler} />}
      />

      <LoadingErrorGuard isLoading={loading} error={error} refetch={onRefetch}>
        <View style={{ flex: 1 }}>
          <UserInfoHeader
            user={user}
            onBackPress={navigateToCheckpoint}
            title={user?.displayName}
            subtitle={user?.jobTitle}
            offDuty={user?.offDuty}
            statusEnabled={user?.company.offDuty}
          />
          <ChannelInfoActions
            actions={actions}
            containerStyles={styles.actionsContainer}
          />
          <Separator color={newColors.separator.main} />
          <View style={styles.attributesTitleWrapper}>
            <Text variant="headline">Attributes</Text>
          </View>
          <ScrollView>
            <View style={styles.attributesWrapper}>{attributesList}</View>
          </ScrollView>
        </View>
      </LoadingErrorGuard>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
  actionsContainer: {
    paddingHorizontal: 16,
    marginVertical: 26,
  },
  attributesTitleWrapper: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  attributesWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 16,
    gap: 8,
  },
});

export default UserProfileScreen;
