const ChannelMentionRegex = new RegExp(/\b(here|all|channel)\b/i);
const ChannelProfileRegex = new RegExp(/\b(profile)\b/i);

const hasRestrictedChannelMentions = (url: string) => {
  const matchProfile = ChannelProfileRegex.test(url);
  const matchMention = ChannelMentionRegex.test(url);
  return matchProfile && matchMention;
};

const buildMarkdownLinkUrl = (url: string) => {
  return url.concat(url.includes("profile") ? "@" : "");
};

export { hasRestrictedChannelMentions, buildMarkdownLinkUrl };
