import { useEffect } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GetChannelsQuery, useGetChannels } from "../queries/GetChannels";

const CREATE_DM = gql`
  mutation ($recipients: [ID!]!) {
    getOrCreateDirectMessageChannel(input: { recipients: $recipients }) {
      channel {
        id
        slug
        name
        messages {
          edges {
            node {
              content
            }
          }
        }
      }
    }
  }
`;

const CREATE_GROUP_CHANNEL = gql`
  mutation ($recipients: [ID!]!, $name: String!) {
    createGroupChannel(input: { members: $recipients, name: $name }) {
      channel {
        id
        slug
        name
        messages {
          edges {
            node {
              content
            }
          }
        }
      }
    }
  }
`;

export const useCreateGroupChannel = () => {
  const [mutation, createGroupChannelResponse] =
    useMutation(CREATE_GROUP_CHANNEL);

  const execute = (recipients: Array<string>, channelName: string) => {
    return mutation({
      variables: {
        recipients,
        name: channelName,
      },
      refetchQueries: [
        {
          query: GetChannelsQuery,
          variables: { filter: { isArchived: false }, first: 20 },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  const channel =
    createGroupChannelResponse?.data?.createGroupChannel.channel?.id || null;

  return { execute, channel };
};

export const useGetOrCreateDm = () => {
  const [
    getOrCreateDirectMessageChannel,
    getOrCreateDirectMessageChannelResponse,
  ] = useMutation(CREATE_DM);
  const { refetch } = useGetChannels();

  useEffect(() => {
    try {
      refetch();
    } catch (error) {}
  }, [refetch]);

  const channel =
    getOrCreateDirectMessageChannelResponse?.data
      ?.getOrCreateDirectMessageChannel.channel?.id || null;

  return { getOrCreateDirectMessageChannel, channel };
};
